import { Outlet } from "react-router-dom";

import React from "react";

function Company() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default Company;
