import { ComingSoon } from "../components";
import React from "react";

function Network() {
  return (
    <>
      <ComingSoon />
    </>
  );
}

export default Network;
