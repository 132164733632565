import { AppView } from "./views";

function App() {
  return (
    <div className="App">
      <AppView />
    </div>
  );
}

export default App;
