import React from "react";
import { ComingSoon } from "../components";

function WhoWeServe() {
  return (
    <>
      <ComingSoon />
    </>
  );
}

export default WhoWeServe;
